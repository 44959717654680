import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import DieselGenerator from "../components/diesel-generator"

class DieselGeneratorIndex extends React.Component {
  render() {
    const dieselGenerators = get(
      this,
      "props.data.allContentfulDieselGenerator.nodes"
    )

    return (
      <Layout location={this.props.location}>
        <Seo title="Blog" />
        <Hero title="Blog" />
        <DieselGenerator dieselGenerators={dieselGenerators} />
      </Layout>
    )
  }
}

export default DieselGeneratorIndex

export const pageQuery = graphql`
  query {
    allContentfulDieselGenerator(
      sort: { fields: [brand___title, primePowerKva], order: ASC }
    ) {
      nodes {
        model
        brand {
          title
        }
        standbyPowerKva
        standbyPowerKwe
        primePowerKva
        primePowerKwe
        catalog {
          title
          file {
            url
          }
        }
        cardImage {
          title
          gatsbyImageData(width: 320)
        }
      }
    }
  }
`
