import React from "react"
import { Link } from "gatsby"

const DieselGenerator = ({ dieselGenerators }) => {
  if (!dieselGenerators) return null
  if (!Array.isArray(dieselGenerators)) return null

  return (
    <div>
      <ul>
        {dieselGenerators.map(dieselGenerator => {
          return (
            <li key={dieselGenerators.model}>
              <Link to={`/diesel-generator/${dieselGenerator.model}`}></Link>
              <div />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default DieselGenerator
